<template>
  <div class="container container-table">
    <div class="columns is-centered is-multiline">
      <div class="container column is-8">
        <div style="max-width:18em; margin: 0 auto;">
          <a href="/" class="edshedlogo">
            <img src="/img/logo-edshed.png" alt="Logo" class="img-responsive center-block">
          </a>
        </div>
        <div class="center-block" style="width: fit-content; text-align: center">
          <img src="/img/logo.png" class="inline-block sublogo">
          <img v-if="$route.query.locale == 'en_US'" src="/img/math-shed-logo-dark.png" class="inline-block sublogo mathslogo">
          <img v-else src="/img/maths-shed-logo-dark.png" class="inline-block sublogo mathslogo">
        </div>
      </div>
      <!-- <img src="/img/Bee.svg" alt="Logo" class="img-responsive center-block logo"> -->
      <div class="column is-8">
        <div class="box box-info" style="color:black">
          <div class="box-header content with-border">
            <div class="text-center">
              <h1>{{ this.$route.name.toUpperCase() }}</h1>
            </div>
          </div>
          <div class="box-body">
            <!-- login form -->

            <!-- errors -->
            <!-- <div class="text-center col-md-12">
                  <router-link to="/logout">Logout</router-link>
                </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
        &nbsp;
    </div>
    <div class="row">
      <div class="container">
        <div class="text-center col-md-4 col-sm-offset-4">
          <p style="font-size:14px; color: #4a4a4a;">
            Copyright &copy; {{ new Date().getFullYear() }} Education Shed Ltd.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { request } from '@/edshed-common/api'

export default {
  name: 'VerifyView',
  mounted () {
    // get code. If no code
    this.$nextTick(() => {
      if (this.$router.history.current.query && this.$router.history.current.query.key) {
        const key = this.$router.history.current.query.key
        console.log(key)
        // send request
        request('POST', 'users/me/verify', { key })
          .then((response) => {
            const data = response.data
            console.log(data)
            if (data.success === 1) {
              alert('Success: Email Verified')
            } else {
              alert('Error')
            }
            this.$router.push('/logout')
          })
          .catch((error) => {
            console.log(error)
            if (error.response && error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
          })
      } else {
        this.$router.push('/logout')
      }
    })
  }
}
</script>
